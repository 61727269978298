<template>
  <v-autocomplete
    v-model="model"
    :items="languages"
    item-text="English"
    item-value="alpha2"
    :label="(multiple ? $tc('common.labels.lang', 2) : $tc('common.labels.lang', 1))"
    :multiple="multiple"
    :chips="multiple"
    :search-input.sync="search"
    return-object
    :clearable="enableClearable"
    outlined
    @input="onInput">
  </v-autocomplete>
</template>

<script>
  // Mixins
  import languagesData from '@/translations/constants/languages_list.json'

  export default {
    name: "SelectLanguage",

    props: {
      multiple: {type: Boolean, default: false},
      enableClearable: {type: Boolean, default: false},
      value: {type: [Array, String, Object], default: () => []},
      isRequired: {type: Boolean, default: true}
    },

    mixins: [],

    data() {
      return {
        languages: languagesData,
        model: [],
        search: null
      }
    },

    created() {
      this.model = this.value;
    },

    methods: {
      onInput(value) {
        this.$emit('input', value ? value['alpha2'] : null);
      }
    }

  }
</script>

<style scoped>

</style>
